
import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const MyBooks = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <h3>My Books</h3>
      <a href="https://www.amazon.com/Shadow-Walker-Battles-Snatched-Trafficker/dp/1480833568/ref=sr_1_1?dchild=1&keywords=shadow+walker+yehuda+fine&qid=1597004682&s=books&sr=1-1" target="_blank" rel="noreferrer">The Shadow Walker: A Rabbi Forged in Fury Battles to Free Kids Snatched by a Sex Trafficker</a>.<strong> March 29th, 2017</strong>.<br/><br/>
   
      <a href="https://www.amazon.com/Real-Deal-Parents-Questions-Answered-ebook/dp/B004XEK89M/ref=sr_1_5?dchild=1&keywords=yehudah+fine&qid=1593699324&s=books&sr=1-5" target="_blank" rel="noreferrer">The Real Deal: For Parents Only - The Top 75 Secret Questions Teens Want Answered Today</a>. <strong>April 14th, 2011</strong>.<br/><br/>

      <a href="https://www.amazon.com/Times-Square-Rabbi-Finding-Lives/dp/158832043X/ref=sr_1_2?dchild=1&keywords=yehudah+fine&qid=1593699385&s=books&sr=1-2" target="_blank" rel="noreferrer">Times Square Rabbi: Finding Hope in Lost Kids' Lives </a>.<strong> June 15th, 2002</strong>.<br/><br/>
      
      <h3>More on the <a href="https://www.amazon.com/Shadow-Walker-Battles-Snatched-Trafficker/dp/1480833568/ref=sr_1_1?dchild=1&keywords=shadow+walker+yehuda+fine&qid=1597004682&s=books&sr=1-1" target="_blank" rel="noreferrer">Shadow Walker</a>:</h3> 
      <blockquote>The Shadow Walker: a spiritually charged thriller that illuminates how Eitan, a Chassidic Rabbi, battles a global sex trafficker across the Big Apple to the outskirts of the Magic Kingdom.

As this sex trafficker arrives in Manhattan. Reb Eitan heads out to stop him. NYPD finds dismembered girls scattered around the city. The Chief Medical Examiner is taken aback at the unsubs capacity for such hellacious crimes. They tag him The Dark Man. He slaughters in the deep vale of darkness.

His father a former Green Beret beat him and his mother mercilessly. After Vietnam, they moved to the Golden Triangle dealing heroin. The boy escapes the horrific abuse into the jungles. There he encounters a sinister master of the dark martial arts. Eitan discovers that each victim is marked with an ancient yet antinomian hexagram reading of the Confucian I Ching.
From these death marks, Rabbi Eitan speculates the killer believes he is a soul taker -- someone who inhales the life force of victims as they take their final breath

Meanwhile, in a neighborhood in Orlando, Florida, Jennifer, the mother of identical twin daughters, is shattered when her girls are kidnapped. Eitan and his crew sprint to rescue them. They, at last, collide into the grim Dark Man.

Yehuda Fine grabs you by the throat and never lets go because he lived the story he tells with such dark clarity. Combine his experience rescuing lost teenagers in Gothams netherworld with a rabbinical scholars knowledge of Judaisms deepest mysteries, and you get a must-read blockbuster like The Shadow Walker.<br/><br/>Chris Mercogiliano, <br/>Teaching the Restless and In Defense of Childhood</blockquote>

<h3>More on the  <a href="https://www.amazon.com/Real-Deal-Parents-Questions-Answered-ebook/dp/B004XEK89M/ref=sr_1_5?dchild=1&keywords=yehudah+fine&qid=1593699324&s=books&sr=1-5" target="_blank" rel="noreferrer">Real Deal</a>:</h3> 
      <blockquote>"Rabbi Fine has been delving into the psyches of adolescents for decades and now with his second book he has written a parental guide on relationships with parents and their teenagers. He has helped shape the counseling technique used in my practice of adolescent medicine."<br/><br/>— James G. Scelfo MD, FAAFP, Medical Director<br/> Disney Marathon and the Citrus Bowl</blockquote>
      <blockquote>"Finally a book for parents of 'tweens and teens that conveys a clear, concise message for American families. Not only a great read, but a book every parent will find extremely helpful. This is the parenting book and is a one of kind endeavor, for no one has spoken to more teens and parents than Rabbi Yehuda Fine." <br/>— Dr. Efrem Nulman, <br/>Senior University Dean of Students,<br/> Yeshiva University, New York </blockquote>
  
  <h3>More on the  <a href="https://www.amazon.com/Times-Square-Rabbi-Finding-Lives/dp/158832043X/ref=sr_1_2?dchild=1&keywords=yehudah+fine&qid=1593699385&s=books&sr=1-2" target="_blank" rel="noreferrer">Finding Hope in Lost Kid's Lives</a>:</h3> 
      <blockquote>"The message Yehudah brings to parents and teens...is that even when a teenager's life is in crisis, caring can produce profound transformation."<br/>—Chicago Tribune</blockquote>
    </Layout>
  )
}

export default MyBooks

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`